.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

body {
  background-image: none;
  font-family: "Open Sans", sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

p {
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}

a {
  color: #333;
  text-decoration: none;
}

.nav-bar {
  position: fixed;
  width: 100%;
  height: 80px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #222;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}

.nav-button {
  padding-top: 0px;
  padding-bottom: 0px;
  font-family: Raleway, sans-serif;
  color: #fff;
  line-height: 80px;
  font-weight: 700;
  text-transform: uppercase;
}

.nav-button.w--current {
  color: #fff;
}

.nav-logo {
  width: auto;
  height: 36px;
  margin-top: 22px;
  padding-left: 20px;
}

.body {
  height: 100%;
  background-position: 50% 50%;
  background-size: 80%;
  background-repeat: repeat-y;
}

.header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.2)),
    to(rgba(0, 0, 0, 0.2))
  );
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2)
  );
}

.page-title {
  display: block;
  margin-top: 0px;
  margin-bottom: 12px;
  text-align: center;
  font-family: Raleway, sans-serif;
  color: #fff;
  font-size: 90px;
  line-height: 90px;
  font-weight: 900;
  text-transform: uppercase;
  text-shadow: 1px 1px 50px #000;
}

.page-text {
  width: 70%;
  min-width: 600px;
  margin-bottom: 25px;
  font-family: Raleway, sans-serif;
  color: #fff;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}

.button-standard {
  min-width: 180px;
  margin-right: 40px;
  padding-right: 50px;
  padding-left: 50px;
  border-radius: 4px;
  background-color: rgba(228, 0, 27, 0.8);
  font-family: Raleway, sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.rezervari-box {
  background-color: #fff;
}

.rezervari-box.top-black {
  height: 60px;
  margin-top: -60px;
  padding-bottom: 12px;
  background-color: transparent;
}

.arrow-down-white {
  display: block;
  width: 20px;
  margin-right: auto;
  margin-left: auto;
}

.section-title {
  margin-top: 0px;
  margin-bottom: 50px;
  font-family: Raleway, sans-serif;
  font-size: 60px;
  line-height: 70px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}

.paragraph-standard {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  text-align: left;
}

.form-element-hidden {
  width: 45%;
}

.form-more {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
}

.slider {
  height: auto;
  margin-top: 50px;
  background-color: transparent;
}

.icon {
  width: 60px;
  height: 60px;
  background-color: rgba(34, 34, 34, 0.5);
  color: #fff;
  font-size: 30px;
  line-height: 60px;
}

.separator---horizontal---nomargin-up {
  display: block;
  width: 800px;
  height: 12px;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;
  padding-top: 70px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.button-standard-100 {
  display: block;
  width: auto;
  padding: 9px 25px;
  border-radius: 4px;
  background-color: rgba(228, 0, 27, 0.8);
  font-family: Raleway, sans-serif;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.contact {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.contact-element {
  width: 45%;
}

.button-navigare {
  margin-top: 10px;
  margin-left: 0px;
  padding-right: 25px;
  padding-left: 25px;
  border-radius: 4px;
  background-color: rgba(228, 0, 27, 0.8);
  font-family: Raleway, sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.footer {
  margin-top: 100px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#222),
    to(#222)
  );
  background-image: linear-gradient(180deg, #222, #222);
}

.logo-footer {
  display: block;
  width: 120px;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
}

.text-footer {
  display: block;
  margin-top: 21px;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.section-standard {
  padding-top: 50px;
}

.slider-image {
  position: relative;
  width: 100%;
  height: 100vh;
}

.addictad-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-addictad {
  display: inline-block;
  margin-top: 10px;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-weight: 400;
  text-align: center;
}

.logo-addictad {
  height: 16px;
  margin-bottom: 2px;
  margin-left: 10px;
  padding-bottom: 0px;
}

.brand {
  display: block;
}

.language-selector {
  float: right;
}

.dropdown-toggle {
  height: 80px;
  margin-left: auto;
  padding-top: 28px;
  padding-bottom: 28px;
  font-family: Raleway, sans-serif;
  color: #fff;
  font-weight: 900;
}

.language-choices.w--open {
  margin-top: -4px;
}

.dropdown-link {
  background-color: #222;
  font-family: Raleway, sans-serif;
  color: #fff;
  font-weight: 700;
}

.dropdown-link.w--current {
  color: #fff;
}

.image-2 {
  width: 24px;
  height: 24px;
}

.button-oferta {
  margin-left: 0px;
  padding-right: 25px;
  padding-left: 25px;
  border-radius: 4px;
  background-color: rgba(228, 0, 27, 0.8);
  font-family: Raleway, sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.oferta-buttons {
  display: -ms-grid;
  display: grid;
  width: 25%;
  margin-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  justify-items: stretch;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.flex-oferta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.container-oferta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-oferta {
  width: 70%;
}

.section-oferta {
  margin-top: 50px;
  padding-top: 50px;
}

.arrow-link {
  width: 100%;
  height: 100%;
}

.fb-link {
  height: 100%;
  margin-right: 20px;
  margin-left: 20px;
  float: right;
}

.fb-icon {
  height: 30px;
  margin-top: 25px;
}

.swiper-end {
  display: none;
}

.stars-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-content-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.card {
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.card:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.card.horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: default;
}

.card.horizontal:hover {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.image-container {
  overflow: hidden;
  padding-bottom: 60%;
  border-radius: 8px;
}

.card-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Raleway, sans-serif;
  font-size: 15px;
  font-weight: 900;
  text-transform: uppercase;
}

.card-holder {
  display: -ms-grid;
  display: grid;
  margin-top: 40px;
  grid-auto-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.in-card-p {
  margin-bottom: 0px;
  margin-left: 5px;
  font-family: Raleway, sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
}

.container-std {
  width: 70%;
  min-height: 60px;
  margin-right: auto;
  margin-left: auto;
}

.container-std.first {
  margin-top: 80px;
}

.facilitati-grid {
  margin: 40px auto;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.icon-text-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.1);
}

.icon-facilitati {
  width: 50px;
  max-height: 50px;
  margin-right: 12px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.facilitati-name {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 700;
}

.slider-room {
  width: 80%;
  height: auto;
  max-width: 1500px;
  margin-top: 70px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
}

.slider-room.content-page {
  overflow: hidden;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 8px;
}

.slide-nav {
  height: 30px;
  font-size: 8px;
  line-height: 8px;
}

.top-button-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.top-button-holder.room {
  margin-bottom: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.button-inline {
  display: block;
  width: auto;
  margin-right: 20px;
  padding-right: 25px;
  padding-left: 25px;
  border-radius: 4px;
  background-color: rgba(228, 0, 27, 0.8);
  font-family: Raleway, sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.room-title {
  margin-top: 0px;
  margin-bottom: 30px;
  font-family: Raleway, sans-serif;
  font-size: 60px;
  line-height: 60px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}

.over-text {
  position: absolute;
  left: auto;
  top: auto;
  right: 2%;
  bottom: 2%;
  width: 500px;
  margin-bottom: 0px;
  padding: 20px 40px;
  border-radius: 4px;
  background-color: hsla(0, 0%, 100%, 0.8);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.pers-holder {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
}

.pers-holder.no-space {
  margin-top: 10px;
  margin-right: 20px;
  font-family: Raleway, sans-serif;
}

.hz-image {
  width: 40%;
  padding-bottom: 25%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 8px 0px 0px 8px;
}

.field-label {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}

.rez-form-grid {
  margin-bottom: 20px;
}

.social-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid hsla(0, 0%, 100%, 0.2);
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
}

.social-footer-icon {
  height: 100%;
  margin-right: 20px;
  margin-left: 20px;
  float: right;
}

.footer-icon {
  height: 30px;
}

.card-image {
  overflow: hidden;
  padding-bottom: 220px;
  border-radius: 8px 8px 0px 0px;
}

.room-details {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-align: left;
}

html.w-mod-js *[data-ix="opacity-0"] {
  display: none;
  opacity: 0;
}

@media screen and (max-width: 991px) {
  .nav-button {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#222),
      to(#222)
    );
    background-image: linear-gradient(180deg, #222, #222);
    line-height: 50px;
  }

  .nav-button.last {
    border-style: none;
  }

  .header {
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(transparent),
      to(transparent)
    );
    background-image: linear-gradient(180deg, transparent, transparent);
  }

  .page-title {
    text-align: center;
  }

  .menu-button {
    padding-top: 30px;
    padding-bottom: 26px;
    color: #fff;
  }

  .menu-button.w--open {
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#222),
      to(#222)
    );
    background-image: linear-gradient(180deg, #222, #222);
  }

  .slider {
    height: auto;
  }

  .separator---horizontal---nomargin-up {
    width: 80%;
    margin-top: 50px;
    margin-bottom: 0px;
  }

  .slider-image {
    height: 75vw;
  }

  .fb-link {
    margin-right: 0px;
    margin-left: 0px;
  }

  .card-holder {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .container-std {
    width: 90%;
  }

  .facilitati-grid {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .over-text {
    width: 300px;
    padding-right: 20px;
    padding-left: 20px;
    text-align: left;
  }

  .social-footer-icon {
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) {
  .nav-bar {
    height: 50px;
  }

  .nav-button {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#222),
      to(#222)
    );
    background-image: linear-gradient(180deg, #222, #222);
    line-height: 50px;
  }

  .nav-button.last {
    border-bottom-style: none;
  }

  .nav-logo {
    height: 32px;
    margin-top: 8px;
  }

  .body {
    overflow: visible;
  }

  .header {
    width: 100%;
  }

  .page-title {
    font-size: 60px;
    line-height: 60px;
    text-align: center;
  }

  .page-text {
    width: auto;
    min-width: auto;
    padding-right: 30px;
    padding-left: 30px;
    font-size: 18px;
    line-height: 28px;
  }

  .menu-button {
    padding-top: 14px;
    padding-bottom: 13px;
    color: #fff;
  }

  .menu-button.w--open {
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#222),
      to(#222)
    );
    background-image: linear-gradient(180deg, #222, #222);
  }

  .section-title {
    font-size: 40px;
    line-height: 40px;
  }

  .paragraph-standard {
    font-size: 14px;
    line-height: 22px;
  }

  .container {
    padding-right: 10px;
    padding-left: 10px;
  }

  .slider {
    display: block;
    overflow: hidden;
    height: auto;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
  }

  .separator---horizontal---nomargin-up {
    width: 80%;
    margin-top: 50px;
    margin-bottom: 0px;
  }

  .dropdown-toggle {
    height: auto;
    margin-top: 12px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .fb-link {
    float: right;
  }

  .fb-icon {
    margin-top: 10px;
  }

  .card-holder {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .facilitati-grid {
    grid-row-gap: 30px;
  }

  .room-title {
    font-size: 40px;
    line-height: 40px;
  }

  .over-text {
    display: none;
  }

  .social-footer-icon {
    float: right;
  }

  .footer-icon {
    margin-top: 10px;
  }

  .room-details {
    font-size: 14px;
    line-height: 22px;
  }
}

@media screen and (max-width: 479px) {
  .nav-bar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  }

  .nav-button {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#222),
      to(#222)
    );
    background-image: linear-gradient(180deg, #222, #222);
    line-height: 50px;
    font-weight: 700;
    text-align: center;
  }

  .nav-button.w--current {
    padding-top: 10px;
  }

  .nav-button.last {
    width: 100%;
    border-bottom-style: none;
  }

  .nav-logo {
    height: 32px;
    margin-top: 0px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
  }

  .body {
    height: 100vh;
    background-image: none;
  }

  .header {
    overflow: visible;
    height: 100%;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(transparent),
      to(transparent)
    );
    background-image: linear-gradient(180deg, transparent, transparent);
    box-shadow: 0 24px 30px 0 rgba(0, 0, 0, 0.07);
  }

  .page-title {
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    text-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  }

  .page-text {
    display: block;
    width: 90%;
    min-width: 0px;
    font-size: 18px;
    line-height: 24px;
  }

  .button-standard {
    display: block;
    width: 80%;
    min-width: auto;
    margin-right: auto;
    margin-bottom: 12px;
    margin-left: auto;
    background-color: rgba(228, 0, 27, 0.65);
  }

  .div-block {
    width: 90%;
  }

  .rezervari-box.top-black {
    margin-top: -60px;
    background-color: transparent;
  }

  .arrow-down-white {
    margin-top: 20px;
  }

  .menu-button {
    padding-top: 13px;
    padding-bottom: 13px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    color: #fff;
  }

  .menu-button.w--open {
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#222),
      to(#222)
    );
    background-image: linear-gradient(180deg, #222, #222);
  }

  .section-title {
    margin-top: 0px;
    margin-bottom: 40px;
    font-size: 30px;
    line-height: 30px;
  }

  .paragraph-standard {
    display: block;
    font-size: 15px;
    line-height: 28px;
  }

  .container {
    padding-right: 0px;
    padding-left: 0px;
  }

  .form-element-hidden {
    width: 100%;
  }

  .slider {
    height: auto;
    margin-top: 20px;
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .icon {
    display: block;
    width: 40px;
    height: 40px;
    margin-right: auto;
    margin-left: auto;
    color: #fff;
    font-size: 20px;
    line-height: 40px;
  }

  .right-arrow {
    width: 40px;
  }

  .left-arrow {
    width: 40px;
  }

  .separator---horizontal---nomargin-up {
    height: 20px;
    margin-top: 0px;
    margin-bottom: 20px;
    padding-top: 50px;
  }

  .button-standard-100 {
    display: block;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px;
  }

  .contact {
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .contact-element {
    width: 100%;
    margin-top: 20px;
  }

  .button-navigare {
    display: block;
    margin-top: 20px;
  }

  .footer {
    margin-top: 50px;
  }

  .text-footer {
    font-size: 12px;
  }

  .nav-menu {
    box-shadow: 0 30px 10px 0 rgba(0, 0, 0, 0.05);
  }

  .section-standard {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 20px;
  }

  .slider-image {
    height: 380px;
  }

  .text-addictad {
    font-size: 12px;
  }

  .logo-addictad {
    height: 14px;
    margin-bottom: 2px;
    padding-bottom: 0px;
  }

  .brand {
    width: 100%;
  }

  .brand.w--current {
    width: 100%;
  }

  .language-selector {
    position: relative;
    width: 70px;
    margin-right: 0px;
    margin-left: auto;
    float: right;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-family: Raleway, sans-serif;
    color: #fff;
    font-weight: 900;
  }

  .dropdown-toggle {
    position: static;
    height: 50px;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    font-family: Raleway, sans-serif;
    color: #fff;
    font-size: 12px;
    font-weight: 900;
  }

  .dropdown-toggle.w--open {
    color: #fff;
    font-size: 12px;
  }

  .language-arrow {
    margin-right: 10px;
  }

  .language-choices.w--open {
    margin-top: 0px;
    padding-top: 0px;
    background-color: #222;
  }

  .dropdown-link {
    color: #fff;
    font-size: 12px;
    font-weight: 900;
  }

  .image-2 {
    width: 20px;
    height: 20px;
    margin-top: 14px;
    margin-left: 20px;
  }

  .button-oferta {
    display: block;
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .oferta-buttons {
    width: 100%;
    margin-bottom: 0px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .container-oferta {
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .text-oferta {
    width: 100%;
    margin-bottom: 10px;
  }

  .section-oferta {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 60px;
  }

  .fb-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    margin-right: 20px;
    margin-left: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .fb-icon {
    height: 24px;
    margin-top: -2px;
  }

  .swiper-end {
    display: block;
    width: 1px;
    height: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .stars-holder {
    margin-bottom: 10px;
  }

  .card-content-holder {
    padding: 20px;
  }

  .card {
    width: 90%;
    margin-right: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 8px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  }

  .card:hover {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .card.horizontal {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .image-container {
    padding-bottom: 75%;
    border-radius: 4px;
  }

  .card-title {
    margin-bottom: 10px;
    font-size: 18px;
  }

  .card-holder {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: scroll;
    width: 100%;
    margin-top: 20px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .container-std {
    width: 90%;
  }

  .container-std.first {
    width: 90%;
    margin-top: 70px;
  }

  .facilitati-grid {
    width: 100%;
    margin-top: 30px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .icon-text-holder {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
    padding: 5px 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
  }

  .icon-text-holder:hover {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .icon-facilitati {
    width: 50px;
    max-height: 40px;
    margin-right: 10px;
  }

  .facilitati-name {
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
  }

  .slider-room {
    width: 100%;
    height: auto;
    margin-top: 20px;
    border-radius: 0px;
    line-height: 8px;
  }

  .slider-room.content-page {
    width: 100%;
    border-radius: 4px;
  }

  .icon-slider {
    font-size: 28px;
  }

  .slide-nav {
    height: 30px;
    margin-bottom: 0px;
    font-size: 6px;
  }

  .top-button-holder {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0px;
    margin-bottom: 15px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .top-button-holder.room {
    margin-bottom: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .button-inline {
    display: block;
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
  }

  .room-title {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 30px;
  }

  .over-text {
    right: auto;
    bottom: 0%;
    display: block;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0px;
    font-size: 14px;
  }

  .pers-holder {
    margin-bottom: 10px;
  }

  .pers-holder.no-space {
    margin-bottom: 10px;
  }

  .hz-image {
    width: 100%;
    padding-bottom: 60%;
    border-radius: 0px;
  }

  .rez-form-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .social-footer-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    margin-right: 20px;
    margin-left: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .footer-icon {
    height: 24px;
    margin-top: -2px;
  }

  .card-image {
    padding-bottom: 75%;
  }

  .room-details {
    display: block;
  }
}
