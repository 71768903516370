.Navbar {
  position: fixed;
  width: 100%;
  min-height: 80px;
  -webkit-transform: translateZ(0);
  z-index: 1000;
  background-color: #222;
  display: flex;
  /* padding: 16px; */
  font-family: sans-serif;
  color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}
.Navbar__Link {
  padding-right: 20px;
  padding-left: 20px;
}

.Navbar__Items {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-shrink: 0;
  width: auto;
  float: right;
}
.Navbar__Items--right {
  margin-left: auto;
}
.Navbar__Link-toggle {
  display: none;
}

.social-icon {
  padding-top: 24px;
  height: 32px;
}

.face-icon {
  height: 30px;
}

.iconSpace {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.hotel-logo {
  height: 36px;
  margin-top: 21px;
}

/* slider - slick CSS */

.slick-arrow {
  width: 60px;
  height: 60px;
  background-color: rgba(34, 34, 34, 0.5);
  border-radius: 4px;
}

.slick-arrow:active {
  width: 60px;
  height: 60px;
  background-color: rgba(34, 34, 34, 0.5);
}

.slick-arrow:enabled {
  width: 60px;
  height: 60px;
  background-color: rgba(34, 34, 34, 0.5);
}

.slick-prev {
  height: 30px !important;
  width: auto !important;
  /* font-family: Arial; */
  /* content:  "⭢";
    font-size: 20px;      */
}

.slick-next {
  height: 30px !important;
  width: auto !important;
  /* font-family: Arial; */
  /* content:  "⭢";
    font-size: 20px;      */
}

@media only screen and (max-width: 1024px) {
  .Navbar__Items,
  .Navbar {
    flex-direction: column;
    min-height: 50px;
  }
  .Navbar__Items {
    display: none;
  }
  .Navbar__Items--right {
    margin-left: 0;
  }
  .Navbar__ToggleShow {
    display: flex;
  }
  .Navbar__Link-toggle {
    align-self: flex-end;
    display: initial;
    padding-top: 30px;
    position: absolute;
    cursor: pointer;
    width: auto;
  }

  .social-icon {
    padding-top: 4px;
    margin-right: 6px;
  }

  .hotel-logo {
    margin-top: 13px;
    height: 24px;
    margin-bottom: 13px;
  }

  .face-icon {
    height: 24px;
  }

  .iconSpace {
    width: 100%;
    padding-right: 40px;
    flex-shrink: 0;
    align-items: center;
  }

  /* slider slick CSS */

  .slick-arrow {
    /* width: 40px;
    height: 40px; */
    background-color: rgba(34, 34, 34, 0.5);
  }

  .slick-arrow:active {
    width: 40px;
    height: 40px;
    background-color: rgba(34, 34, 34, 0.5);
  }

  .slick-arrow:enabled {
    width: 40px;
    height: 40px;
    background-color: rgba(34, 34, 34, 0.5);
  }
}

.fas {
  font-size: 18px;
}
@media only screen and (max-width: 479px) {
  .Navbar__Link-toggle {
    align-self: flex-end;
    display: initial;
    padding-top: 16px;
    position: absolute;
    cursor: pointer;
    width: auto;
  }
}
