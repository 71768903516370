/* @import "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"; */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700|Raleway:400,700,900&display=swap");

.hiddenLang {
  display: none;
}

.slick-prev {
  left: 2% !important;
  z-index: 1;
}

.slick-next {
  right: 2% !important;
  z-index: 1;
}

.header {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-position: 75% 50%;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.2)),
    to(rgba(0, 0, 0, 0.2))
  );
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2)
  );
}

.slider-image {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-position: center;
}

.card-image,
.hz-image,
.image-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 1024px) {
  .dropdown-toggle {
    margin-top: 0px;
  }
}

@media screen and (max-width: 479px) {
  .slider-image {
    background-repeat: no-repeat;
    background-size: cover;
    height: 380px;
    background-position: center;
  }
}
